import React from 'react';
import './about_us.scss';
// const checkSVG = require('../images/check.svg');
const checkSVG = require('../images/checkTwo.png');
import { Link } from 'gatsby';

export default function AboutUs(props) {
  return (
    <div className="about-container">
      <div>
        <h2>{props.language.title}</h2>
        <p id="about">{props.language.text}</p>
      </div>
      <div>
        <h2>{props.language.titleTwo}</h2>
        <p id="about">{props.languageTwo.text}</p>
        <div className="check-container">
          <div>
            <div className="what-item">
              <img className="lw-pricing" src={checkSVG} alt="check" />
              <Link to="/skin_leather" style={{ textDecoration: 'none' }}>
                <p className="what-text">{props.languageTwo.checkOne}</p>
              </Link>
            </div>
            <div className="what-item">
              <img className="lw-pricing" src={checkSVG} alt="check" />
              <Link to="/livestock_fish" style={{ textDecoration: 'none' }}>
                <p className="what-text">{props.languageTwo.checkTwo}</p>
              </Link>
            </div>
          </div>
          <div>
            <div className="what-item">
              <img className="lw-pricing" src={checkSVG} alt="check" />
              <Link to="/wool" style={{ textDecoration: 'none' }}>
                <p className="what-text">{props.languageTwo.checkThree}</p>
              </Link>
            </div>
            <div className="what-item">
              <img className="lw-pricing" src={checkSVG} alt="check" />
              <Link to="/nuts_fruit" style={{ textDecoration: 'none' }}>
                <p className="what-text">{props.languageTwo.checkFour}</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
