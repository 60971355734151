import React from 'react';
import { Fade } from 'react-slideshow-image';
import './slideshow.scss';
let myRef;
const fadeImages = [
  'https://images.unsplash.com/photo-1504495395630-bc2ab1e5662c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80',
  'https://images.unsplash.com/photo-1545368832-17e601321b7e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1934&q=80',
  'https://images.unsplash.com/photo-1566847863887-78766c6a30db?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80',
  'https://images.unsplash.com/photo-1502825751399-28baa9b81efe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80',
];

const fadeProperties = {
  duration: 4000,
  arrows: false,
  transitionDuration: 500,
  infinite: true,
  indicators: false,
};

class Slideshow extends React.Component {
  constructor(props) {
    super(props);
    myRef = React.createRef();
  }
  scrollToMyRef = () => {
    if (window.screen.width < 767) {
      window.scrollTo(0, myRef.current.offsetTop - 70);
    } else {
      window.scrollTo(0, myRef.current.offsetTop - 70);
    }
  };

  render() {
    return (
      <div className="slide-container container">
        <Fade {...fadeProperties}>
          <div className="each-fade">
            <div className="image-container">
              <img src={fadeImages[0]} />
              <div className="text-container">
                <p>{this.props.language.textOne}</p>
              </div>
            </div>
          </div>
          <div className="each-fade">
            <div className="image-container">
              <img src={fadeImages[1]} />
              <div className="text-container">
                <p>{this.props.language.textTwo}</p>
              </div>
            </div>
          </div>
          <div className="each-fade">
            <div className="image-container">
              <img src={fadeImages[2]} />
              <div className="text-container">
                <p>{this.props.language.textThree}</p>
              </div>
            </div>
          </div>
          <div className="each-fade">
            <div className="image-container">
              <img src={fadeImages[3]} />
              <div className="text-container">
                <p>{this.props.language.textFour}</p>
              </div>
            </div>
          </div>
        </Fade>
        <div className="oval-button">
          <a onClick={this.scrollToMyRef}>
            <svg
              className="oval-button-arrow"
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="5"
              viewBox="0 0 11 5"
            >
              <path
                fill="none"
                fillRule="evenodd"
                stroke="#992E80"
                d="M1 .005l4.748 4.747L10.5 0"
              />
            </svg>
          </a>
        </div>
        <div ref={myRef} />
      </div>
    );
  }
}

export default Slideshow;
