import React from 'react';
import SEO from '../components/seo';

import '../components/index.scss';
import Loader from '../components/loader';
import ContextConsumer from '../components/context';
import Slideshow from '../components/slideshow';
import AboutUs from '../components/about_us';

const EN = require('../components/copy/EN.json');
const IT = require('../components/copy/IT.json');
let currentLanguage;
let language;

interface Props {}
interface State {
  isLoading: boolean;
}

class IndexPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  loader() {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  renderIndex() {
    return (
      <>
        <ContextConsumer>
          {({ data }) => {
            language = data.contextLanguage;

            language === 'IT' ? (currentLanguage = IT) : (currentLanguage = EN);
            return (
              <>
                <SEO title="Bocciardo - Global Brokers" />
                <Slideshow language={currentLanguage.Hero} />
                <AboutUs
                  language={currentLanguage.About}
                  languageTwo={currentLanguage.Check}
                />
              </>
            );
          }}
        </ContextConsumer>
      </>
    );
  }

  render() {
    return <>{this.renderIndex()}</>;
  }
}

export default IndexPage;
